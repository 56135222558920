import { PageSeo } from 'components/common';
import { Layout } from 'components/layout';
import { TRANSLATIONS } from 'constants/translations';
import { withSSGPage } from 'hoc/WithServerPage/withSSGPage';
import Template404 from 'templates/Errors/Template404';

import type { NextPageWithLayout } from 'types';
import { PageType } from 'types';

export const getStaticProps = withSSGPage(async () => {
  return {
    props: {}
  };
}, {
  translations: [TRANSLATIONS.COMMON, TRANSLATIONS.HOME],
  pageType: PageType.Error404,
  revalidate: false
});

const Page: NextPageWithLayout<PageType.Error404> = () => {

  return (
    <>
      <PageSeo seoProps={{ noindex: true }} />
      <Template404 />
    </>
  );
};

Page.getLayout = (page) => (
  <Layout
    {...layoutProps}
  >
    {page}
  </Layout>
);

const layoutProps = {
  fullscreen: true,
  openInAppBanner: {
    props: {},
    isVisible: false
  }
};

export default Page;
