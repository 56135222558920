import React from 'react';
import { useTranslation } from 'next-i18next';

import { Button, ImageMessage, Link } from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';

import styles from './ErrorTemplate.module.scss';

const Template404 = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <ImageMessage
        image={IMAGES.ERRORS.NOT_FOUND}
        imageProps={{
          width: 414,
          height: 246,
          alt: '404 Not found'
        }}
        title={t('ERROR_TEMPLATES.404.TITLE')}
        subtitle={t('ERROR_TEMPLATES.404.SUBTITLE')}
      >
        <Link href={APP_ROUTES.HOME} prefetch={false} passHref>
          <Button className={styles.ctaBtn} tag="a" variant="primary">{t('ERROR_TEMPLATES.404.CTA')}</Button>
        </Link>
      </ImageMessage>
    </div>
  );
};

export default Template404;
